.panel {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  ::-webkit-scrollbar {
    width: 10px;
    max-height: 100px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 8px;
    margin: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}